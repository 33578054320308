
.previewSection {
    font-size: 16px;

    label, .lightText {
        color: #7D7D7D;
    }

    thead th {
        font-size: 12px;
        color: #7D7D7D;
        font-weight: 700;
    }

    .lightText {
        font-size: 12px;
    }

    .keywordSection {
        display: flex;
        row-gap: 0.5rem;
        column-gap: 0.5rem;
        flex-wrap: wrap;
        .keywordSpan {
            background: #D8F5F5;
            border-radius: 6px;
        }
    }
}

.editHeader {
    color: #595F72;
    font-weight: 600 !important;

    .editLink {
        color: #007f8d;
        cursor: pointer;
        font-weight: 400;
    }
}

.journalTitleStyle {
    font-weight: 500;
}

.titleAstractDiv {
    max-height: 80vh;
    height: auto;
    overflow: auto;
}

.font-weight-semi-bold {
    font-weight: 600;
}

.stepFiveForm {
    ul li {
        list-style-type: disc;
    }
    ol li {
        list-style-type: decimal;
    }
}

.h5Fontsize {
    font-size: 1.25rem;
}