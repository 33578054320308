@import '../../../../../theme.scss';
.action-div {
    // min-height: 45vh;
    height: auto;

    button {
        width: 10rem;
    }
}

.action-div .stepSixActionBtn button {
    width: 100%;
    margin-bottom: 1rem;
}

.skip-Button {
    color: $secondary-color;
	background: #fff;
}

.error-description{
    max-height: 65vh;
    overflow-y: auto;
}

.no-underline {
    text-decoration: none !important;

    :hover {
        text-decoration: none !important;
    }
}

.mh-30 {
    height: 50vh;
    overflow: auto;
}

.width-auto {
    width: auto !important;
}

.downloadButton {
    color: #007f8d;
    background: #fff;
    border: 1px solid #007f8d;
}