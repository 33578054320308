@import '../../theme.scss';
.remirrorEditorDiv .ProseMirror.remirror-editor {
    overflow: hidden;
    // max-height: 70vh;
    padding: 10px;
    box-shadow: 0px 0px 0px 0px !important;
    // border-bottom: 1px solid #dee2e6;
    // border-left: 1px solid #dee2e6;
    // border-right: 1px solid #dee2e6;
    // border-top-left-radius: 0px;
    // border-top-right-radius: 0px;
}

.flex-wrap-div {
    flex-wrap: wrap;
    row-gap: 8px;
    padding: 9px 2px;
    border: 2px solid #ccc;
    border-radius: 7px;
}

.MuiBox-root.css-1wz6ik3:first-child {
    margin-left: 8px;
}

.remirror-table-delete-inner-button {
    height: auto !important;
}

.remirror-button {
    right: 5px !important;
    top: 5px !important;
}

.dropDownStyle {
    height: 18px !important;
    width: 18px !important;
    font-size: 10px;
    cursor: pointer;
}

.iconStyle {
    background-color: white;
    padding: 2px;
    border-radius: 4px;
    border: gray 0.5px solid;
}

.findReplaceStyle {
    display: inline-block;
    width: 100%;
    padding: 0 10px 0px 0px;
}

.w-70 {
    width: 70%;
}

.sticky-toolbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    cursor: pointer;
    column-gap: 5px;
    position: sticky;
    top: 72px;
    z-index: 1035;
    @media (max-width: 768px) {
		// top: 152px;
		position: relative;
	}
}

.remirror-theme .ProseMirror p {
    margin-bottom: 1rem !important;
}

.flex-wrap-toolbar {
    flex-wrap: wrap;
    row-gap: 8px;
    padding: 9px 2px;
}

.remirror-editor-wrapper {
    padding-top: 0px !important;
}

.tooltip-text-btn {
    font-weight: 500;
}