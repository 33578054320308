
.editingBtnStyle{
    font-weight: 500;
    color: #007f8d;
    border: #007f8d 1px solid;
}

.editingBtnStyle:hover {
    color: white;
    background-color: #007f8d;
}