@import '../../../../../theme.scss';

.w-200 {
	width: 200px;
}

.flex-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.w-200 {
	width: 200px;
}

.errorModal {
	height: 50vh;
	overflow-y: scroll;
}

.sticky-div-1 {
	position: sticky;
	top: 0;
	@media (max-width: 768px) {
		// top: 80px;
		position: relative;
	}
}

.sticky-div-2 {
	position: sticky;
	top: 72px;
}

.common-height {
	min-height: 60vh;
	height: auto;
}

.tabList {
	list-style-type: none;
	padding-top: 1.15rem;
	overflow-x: auto;
	li {
		padding: 0 1.5rem 0.2rem 0rem;
		box-sizing: border-box;
		font-size: 16px;
		cursor: pointer;
		// border-bottom: 1px solid #dee2e6;
		flex: 100%
	}

	li.active {
		border-bottom: 3px solid $secondary-color;
	}
}

.tabTitle {
	font-size: 12px;
}

.activeTabTitle {
	color: $secondary-color;
	font-size: 12px;
}

.btnOutline {
	color: $secondary-color;
	background: #fff;
	border: 1px solid $secondary-color;
	// width: 200px;
}

.btnOutline:hover {
    color: white;
    background-color: $secondary-color;
}

.defaultBtnWidth {
	// width: 200px;
		// padding: 0;
}

.tableHead {
	color: $light-dark;
	font-size: 12px;
	font-weight: 700;
}

.tBody {
	color: #323F4D;
	font-size: 16px;
	font-weight: 400;
}

.cursorAuto {
	&:hover {
		cursor: auto;
	}
}
.diabledTab {
	font-size: 12px;
	color: $light-dark;
	font-weight: 700;
	&:hover {
		cursor: auto;
	}
}

.sideBar {
	position: sticky;
	top:0
}

.cursor-progress {
	cursor: progress !important;
}

.textLink {
	color: $secondary-color;
}

@media screen and (max-width: 989px) {
	.content-section .skipErrorBtn, .content-section .previousBtn {
		padding: 0.3rem 1rem;
    	width: auto;
	}
	.content-section .nextBtn, .finishBtn {
		padding: 0.3rem 0.5rem;
	}

	.content-section .skipBtn {
		padding: 0.3rem 2.5rem;
		width: auto;
	}
}

@media screen and (max-width: 804px) {
	.content-section button {
		font-size: 14px;
	}
}

@media screen and (max-width: 767px) {
	div.submisionProcessBox, div.stepsSection{
		margin: 0;
	}
	div.stepsSection, div.content-section {
		padding: 0;
	}

}

@media screen and (max-width: 558px) {
	.content-section .previousBtn, .content-section .nextBtn,.content-section .skipErrorBtn, .content-section .skipBtn, .finishBtn {
		width: 100%;
		display: block;
		margin-bottom: 0.3rem;
	}
}

.link {
	color: $secondary-color;
	cursor: pointer;
}

.link:hover {
	text-decoration: none;
	color: $secondary-color;
}

.z-index-10000 {
	z-index: 1038 !important;
}

.figureCaptionList {
	max-height: 50vh;
    overflow: auto;
}