@import '../../../../theme.scss';

.link {
	color: $secondary-color;
	cursor: pointer;
}

.link:hover {
	text-decoration: none;
	color: $secondary-color;
}

.journalTitle {
	font-weight: 500;
}

.modalBody {
	height: 80vh;
	overflow-y: auto;
}

.h3FontSize {
	font-size: 1.75rem;
}
