@import '../../../../../theme.scss';

.fileUploader {
	box-sizing: border-box;

	label {
		width: 100%;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align:center;
		border: 1px dashed #CDCDCD;
		border-radius: 4px;
	}
}

.w80 {
	width: 90%;
}
.textLink {
	color: $secondary-color;
}

.link {
	color: $secondary-color;
	cursor: pointer;
}

.textLight {
	color: #7D7D7D;
}

.actionBtn {
	border: 1px solid $secondary-color;
	color: $secondary-color;
	background-color: #fff;
}

.deleteBtn {
	padding: 0;
	color: red;
	background-color: #fff;
}

.errorMessage {
	color: red;
	font-size: 12px;
}

.downloadLink {
	color: $secondary-color;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.tableHead {
	color: #7D7D7D;
	font-size: 12px;
	font-weight: 700;
}

.tBody {
	color: #323F4D;
	font-size: 16px;
	font-weight: 400;
}

.modalBody {
	height: 80vh;
	overflow-y: auto;
}

.fileTablePrimaryBtn {
	color: #007f8d !important;
}

.secondaryTextButton {
	color: #007f8d;
    background: #fff;
}

.keywordSpan {
    background: #D8F5F5;
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.btn {
    background: transparent;
    color: #FF0000;
}

.keywordSpan2 {
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.modalHeaderSubtext {
	font-size: small;
}