@import '../../theme.scss';

.primaryText {
    color: $secondary-color;
}

.scopeData {
    div {
        margin-bottom: 10px;
    }
}

.selectedCardBorder {
    border-color: $secondary-color !important;
    border-width: 2px !important;
}

.h5FontSize {
    font-size: 1.25rem;
}

.h4FontSize {
    font-size: 1.5rem;
}