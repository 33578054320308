@import "../../../../../theme.scss";

.authorName {
    color: #323F4D;
    font-size: 16px;
}

.orcid {
    color: #7D7D7D;
    font-size: 12px;
}

.inputNumberStyle {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
}

.reactSelectMenuList {
    background-color: white;
    max-height: 200px;
    overflow: auto;
}

.raectSelectMenu {
    border: 0.5px solid rgba(65, 65, 65, 0.589);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.reactSelectOption {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.reactSelectOption:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: #007bff;
    color: white;
}

.reactSelectIndicator {
    margin: none;
    padding: none;
    right: 1px;
}

.reactSelectMultivalue {
    background-color: #ced4da;
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.reactMultiValueRemove {
    padding: 2px;
}

.reactMultiValueRemove:hover {
    padding: 2px;
    color: red;
}

.reactSelectControl {
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.reactSelectControl:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.reactSelectControl:hover {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.reactSelectControl:active {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.reactSelectControl:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.reactSelectControl:focus-visible {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.reactSelectContainer {
    z-index: 100;
}

.primaryFont {
    color: $secondary-color;
}

.primaryBtn {
    color: $secondary-color;
    border-color: $secondary-color;
    background-color: #fff;
}