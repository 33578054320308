body #root {
  color: #4A4A4A;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323F4D;
  background: #E5E5E5;
}

body button{
  color: #ffffff;
  background: #007f8d;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

body button:focus{
  box-shadow: 0 0 0 0.2rem #30e6e2
}

body button:disabled{
  opacity: 0.6;
}

body a{
  color: #007f8d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::placeholder, textarea::placeholder {
  color: #cdcdcd !important;
}

.cursor-pointer {
  cursor: pointer;
}

div.styles_chip-input__3aumB .styles_chip__i-PT7{
  padding: 0.25rem 0.5rem;
  background-color: #D8F5F5;
  border-radius: 10px;
  border-color: #D8F5F5;
}

@media (min-width: 1200px) {
.container-xl {
    max-width: 1500px;
}
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.carousel-indicators li {
  background-color: #313f4c;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 12px;
}
 
.carousel-indicators .active {
  background-color: #007f8d;
}
 
.carousel-indicators {
  top: 430px;
  @media (max-width: 576px) {
    top: 520px
  }
}

.maxWidthHome {
  /* max-width: 2048px; */
  margin: auto !important;
}

.highlightClass:active {
  background-color: #ff4d4d !important;
}

.highlightClass:focus {
  background-color: #ff4d4d !important;
}

.highlightClass:hover {
  background-color: #ff4d4d !important;
}

.selectedViolation {
  background-color: #ff4d4d !important;
}

.toastProgressBar {
  /* animation: slide-progress var(--toastify-toast-duration) linear forwards !important; */
  animation: slide-progress 3s linear forwards !important;
}

.toastProgressBar5s {
  /* animation: slide-progress var(--toastify-toast-duration) linear forwards !important; */
  animation: slide-progress 5s linear forwards !important;
}

.toastProgressBar10s {
  /* animation: slide-progress var(--toastify-toast-duration) linear forwards !important; */
  animation: slide-progress 10s linear forwards !important;
}

.Toastify:hover {
  .toastProgressBar {
    animation-play-state: paused !important;
  }
}
.Toastify:hover {
  .toastProgressBar10s {
    animation-play-state: paused !important;
  }
}
.Toastify:hover {
  .toastProgressBar5s {
    animation-play-state: paused !important;
  }
}

@keyframes slide-progress {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  overflow: hidden;
}