.secondaryTextColor {
    color: #007f8d !important;
}

.primaryButton {
    color: white;
    background-color: #007f8d;
}

.h2FontSize {
    font-size: 2rem;
}