@import "../../../theme.scss";

.notFound  {
    width: 100%;
    height: 10vh;
}

.w-200 {
    width: 200px;
}

.h4FontSize {
    font-size: 1.5rem;
}