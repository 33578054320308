@import '../../theme.scss';
/* Style the header with a grey background and some padding */
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.logo {
    width: 200px;
    height: auto;

    @media (max-width: 992px) {
       width: 180px;
       position: relative;
       display: flex;
    //    margin-left: 3.3rem;
    //    margin-bottom: 0.8rem;
    }
}

.link {
    color: black;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    font-family: 'lato';

    @media (max-width: 992px) {
        display: none;
    }
}

a:hover.link {
    color: black !important;
}

.newManuscriptLink {
    background-color: $light-secondary-color;
    color: $secondary-color;
    font-weight: 600;
    padding: 10px;

    @media (max-width: 992px) {
        display: none;
    }
    font-family: 'lato';
    font-weight: 400;
    font-style: normal;
}

.newManuscriptButton {
    background-color: $light-secondary-color;
    color: $secondary-color;
    font-weight: 600;
    padding: 10px;
}

.newManuscriptBurgerBtn {
    background-color: $light-secondary-color;
    color: $secondary-color;
    font-weight: 600;
    padding: 10px;
}

.headerLink {
    @media (max-width: 992px) {
        display: none;
    }
}

button:hover.newManuscriptLink {
    color: $secondary-color !important;
}

button:hover.newManuscriptButton {
    color: $secondary-color !important;
}

.alertButton {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding: 4px 8px;
    border: none;
    border-radius: 0px !important;
    background-color: #ba4743;
}

.headerButton {
    font-size: 16px;
    font-weight: 600;
    color: inherit;
    cursor: pointer;
    // margin: 0rem 0.5rem;
    background-color: transparent;
    border: none;
    // padding: 0.6rem;
    font-family: 'lato';
    font-weight: 400;
    font-style: normal;
    @media (max-width: 992px) {
        position: relative;
    }
}

.loginButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: inherit;
    cursor: pointer;
    margin: 0rem 0.5rem;
    background-color: transparent;
    border: none;
    padding: 0.6rem;
    border-radius: 0.25rem;
}

.loginButton:hover {
    background-color: #e2e6ea;
}

.action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}



.validators {
    display: block;
    background-color: #ffbaba;
    color: #d8000c;
    font-size: 12px;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid;
}

.formErrors {
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
}

.formFooter {
    margin-top: 10px;
    font-size: 14px;
}

.customFormField {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.customButton {
    color: #fff;
    padding: 0.5rem 2rem;
    border: none;
    background-color: #000;
}

.popoverForm {
    margin: 20px;
    font-size: 15px;
}

.alertColor{
    color:  $secondary-color;
}

span.alertColor{
    @media (max-width: 992px) {
        display: none;
    }
}

.dropdownLogo{
    margin-right: 1rem;
    font-size: 16px; 
    vertical-align: middle; 
}

.adbanner{
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 480px) {
        display: none;
    }
}

/*Report button*/

.customInput input{
    border-radius: 0px;
}

.customInput input:focus{
    box-shadow: none;
    border-color: inherit;
}

.reportButton{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 0.5rem 2rem;
    border: none;
    background-color: $primary-color;
}

.reportButton:disabled{
    opacity: 0.6;
}

.reportLink{
    position: fixed;
    top: 70%;
    right: 0;
    color: #fff;
    padding: 0.25rem 1rem;
    margin-right: 0.5rem;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    -webkit-transform: rotate(270deg) translate(100%,-50%);
    -moz-transform: rotate(270deg) translate(100%,-50%);
    -ms-transform: rotate(270deg) translate(100%,-50%);
    transform: rotate(270deg) translate(100%,-50%);
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    z-index: 10001;
    opacity: 0.6;
}

.link:hover{
    opacity: 1;
}

.registerForm{
    width: auto;
}
  
//Mobile Navigation Bar
  
.menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1039;
    -webkit-user-select: none;
    user-select: none;
}

.menuToggle.active{
    position: fixed;
}

.menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

.menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #000000;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}

.menuToggle span:first-child {
    transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
}
.menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

.menu {
    position: fixed;
    top: 0px;
    left: 0px;
    list-style-type: none;
    width: 210px;
    height: 100vh;
    padding: 3rem 1.5rem;
    background-color: rgb(251, 251, 251);
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menuShadow {
    box-shadow: 0 0 10px #85888C;
}

.menu li {
    padding: 10px 0;
    transition-delay: 2s;
}

.menuToggle input:checked ~ ul {
    transform: none;
}

.navigation {
    display: none;
    @media (max-width: 992px) {
        display: block;
    }
}

.customAvatar{
    color: $primary-color;
    height: 32px;
    margin-right: 0.5rem;
}

.btnAddManuscript {
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }
}

.containerClass {
    padding-top: 5px;
    @media (max-width: 992px) {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1039;
        background-color: rgb(251, 251, 251);
    }
}

.headerUserDropdown {
    // border-left: 1px solid lightgrey;
}

.userBadge {
    background-color: $secondary-color;
    color: white;
    font-weight: 800;
    font-family: 'lato';
}