$primary-color: #323F4D;
$secondary-color: #007f8d;
$light-secondary-color: #ecfbfa;
$default-color: #001841;
$link-color: #0056b3;
$background-light: #f2f2f2;
$dark: #1c1d1e;
$light-dark: #7D7D7D;

@mixin card {
    color: #fff;
    font-weight: 600;
    padding: 2%;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content; 
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    } 
}
  
  

 