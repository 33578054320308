@import '../../theme.scss';

.homePage {
  h3 {
    font-size: 1.75rem;
  }

  button {
    width: 19rem;
  }

  .linkText {
    color: $secondary-color;
    &:hover {
    color: $secondary-color;
    }
  }

  .spanText {
    color: $secondary-color;
    cursor: pointer;
    &:hover {
    color: $secondary-color;
    text-decoration: underline;
    }
  }

}
.hightlightText {
  color: $secondary-color;
  font-family: 'lato';
  font-weight: 500;
  font-style: normal;
}

.headerText {
  h1 {
    font-size: 3rem;
  }

  p {
    color: $light-dark;
    font-size: 1.5rem;
  }
}

.iconStyle {
  font-size: 1.5rem;
  color: $secondary-color;
}

.bannerDiv {
  height: 300px;
}

.bannerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 320px;
}

.streamlineBcakground {
  background: url("./assets/homepage\ background\ -\ streamline.png") no-repeat;
  background-size: 100%;
  background-position: left center;
  padding-bottom: 5vh;
  @media (max-width: 768px) {
    background: #fbfbfb;
  }
}

.saveTimeBcakground {
  background: url("./assets/homepage\ background\ -\ save\ time.png") no-repeat;
  background-size: 100%;
  background-position: left center;
  padding-bottom: 5vh;
  @media (max-width: 768px) {
    background: #fbfbfb;
  }
}

.quoteBcakground {
  background: url("./assets/homepage\ background\ -\ quote.png") no-repeat;
  background-size: 100%;
  background-position: left center;
  padding-bottom: 5vh;
  @media (max-width: 768px) {
    background: #fbfbfb;
  }
}

.findPublicationBcakground {
  background: url("./assets/homepage\ background\ -\ find\ publication.png") no-repeat;
  background-size: 100%;
  background-position: left center;
  padding-bottom: 5vh;
  @media (max-width: 768px) {
    background: #fbfbfb;
  }
}

.journyDiv {
  background-color: #3dcecb;
  padding: 5%;
}

.nameBadge {
  background: white;
  // height: auto;
  // width: auto;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 24px;
  color: #007f8d;
  font-weight: 600;
  padding: 8px 16px 8px 16px;
  margin-left: 4.3rem;
  margin-top: 3rem;
}

.journyDivText {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  line-height: 1.5rem;
}

.journeyDivButton {
  background-color: #007f8d;
  border-radius: 500px;
  padding: 1% 5%;
  color: white;
  font-weight: 600;
  font-family: 'lato';
  @media (max-width: 768px) {
    padding: 2% 10%;
  }
}

.quoteDivText {
  color: #007f8d;
  font-family: 'lato';
}

.homePageButton {
  background-color: #007f8d;
  border-radius: 500px;
  padding: 10px 60px;
  color: white;
  font-weight: 600;
  font-family: 'lato';
}

.journyDivIcons {
  align-self: center;
  min-height: 8rem;
  max-height: 8rem;
}

.carouselButton {
  background-color: #007f8d;
  border-radius: 500px;
  padding: 1% 3%;
  color: white;
  font-weight: 600;
  z-index: 30;
  @media (max-width: 768px) {
    padding: 2% 10%;
  }
}

.cardButton {
  background-color: #007f8d;
  border-radius: 500px;
  padding: 4% 12%;
  color: white;
  font-weight: 600;
  z-index: 30;
  position: relative;
  @media (max-width: 768px) {
    padding: 2% 10%;
  }
}

.stickyIndicators {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100;
}

.btnImgMaxHeight {
  max-height: 155px;
}

.carouselHeading {
  font-family: 'lato';
  font-weight: 500;
  font-style: normal;
}

.carouselText {
  font-family: 'lato';
  font-weight: 400;
  font-style: normal;
  line-height: 1.5rem;
}

.fontLato {
  font-family: 'lato';
}

.quoteTextLineHeight {
  line-height: 1.5rem;
}

.headingDivHeight {
  min-height: 260px;
  overflow: hidden;
}

.roundedCorner {
  border-radius: 25px;
} 

.headingDivHeightMobile {
  min-height: 380px;
  overflow: hidden;
}

.JustifyContentEvenly {
  justify-content: space-evenly;
}