@import '../../theme.scss';

.lightDark{
    color: $light-dark;
}

.customLink{
    color: $secondary-color;
    cursor: pointer;
    &:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}

.h4FontSize {
    font-size: 1.5rem;
}
